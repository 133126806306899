<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'Contract', title: 'Договори' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_contract)"
        :modal="show_contract_dialog"
        @updateItemModal="contractUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік договорів
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openContractCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий договір</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="tableHeaders"
        :items="contracts"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onContractItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon size="24" class="mr-2" :color="getAccountIcon(item.service_icon, 'color')">
          {{ getAccountIcon(item.service_icon, 'icon') }}
        </v-icon>
      </template>
      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_close="{ item }">
        <span>
          {{ item.date_close | formatDate }}
        </span>
      </template>
      <template v-slot:item.prolonged="{ item }">
        <v-simple-checkbox v-model="item.prolonged" disabled :ripple="false"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import {GET_CONTRACTS_BY_FLAT} from "@/store/actions/contract";
import {getAccountIcon} from "@/utils/icons";

export default {
  name: "HWP_Modal_FlatContract",
  props: {},
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  data() {
    return {
      selected_contract: {},
      show_contract_dialog: false,
      tableHeaders: [
        { text: '', value: 'icon', width: 20 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Підписант', value: 'contract_owner' },
        { text: 'Пролонгований', value: 'prolonged' },
        { text: '№ договору', value: 'number' },
        { text: 'Дата початку', value: 'date_start' },
        { text: 'Дата завершення', value: 'date_close' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      contracts: 'getContracts',
      flat: 'getFlat'
    })
  },
  methods: {
    ...mapActions({
      fetch_contracts: GET_CONTRACTS_BY_FLAT
    }),
    getAccountIcon,
    contractUpdateItemModal() {
      this.show_contract_dialog = false
      this.selected_contract = {}
    },
    openContractCreateDialog() {
      this.selected_contract = {
        flat_id: this.flat.id
      }
      this.show_contract_dialog = true
    },
    onContractItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_id = this.flat.id

      this.selected_contract = local_payload
      this.show_contract_dialog = true
    },
  },
  created() {
    this.fetch_contracts(this.flat.id)
  }
}
</script>

<style scoped lang="scss">

</style>